<template>
    <div class="contact_info_box">
        <div style="padding-bottom: 16px; height: 390px">
            <p class="info">
                <span>交易单号</span>
                {{ arrive.tradeNo ? arrive.tradeNo : '- -' }}
            </p>
            <p class="info">
                <span>付款人</span>
                {{ arrive.externalUserName ? arrive.externalUserName : '- -' }}
            </p>
            <p class="info">
                <span>收款总金额</span>
                {{ arrive.amount ? arrive.amount : '- -' }}
            </p>
            <p class="info">
                <span>收款人</span>
                {{ arrive.adminName ? arrive.adminName : '- -' }}
            </p>
            <p class="info">
                <span>当前交易状态</span>
                {{
                    arrive.tradeState == 1
                        ? '已完成'
                        : arrive.tradeState == 3
                        ? '已完成有退款'
                        : '- -'
                }}
            </p>
            <p class="info">
                <span style="margin-left: -10px">
                    <i style="color: red">*</i>新交易状态</span
                >
                <el-select
                    v-model="tradeState"
                    size="small"
                    style="width: 225px"
                    clearable
                    placeholder="请选择新交易状态"
                >
                    <el-option label="已完成" :value="1"></el-option>
                    <el-option label="已完成有退款" :value="3"></el-option>
                </el-select>
            </p>
        </div>
        <div class="footer_btn">
            <el-button size="mini" round @click="onClose">取 消</el-button>
            <el-button type="primary" size="mini" @click="onSubmit" round
                >保 存</el-button
            >
        </div>
    </div>
</template>

<script>
import { arriveUpdate } from '@/api/arrive/arrive.js';
export default {
    data() {
        return {
            arrive: {},
            tradeState: '',
        };
    },
    methods: {
        getData(row) {
            this.tradeState = '';
            this.arrive = row;
            // console.log(row);
        },
        onClose() {
            this.$emit('close');
            this.tradeState = '';
        },
        onSubmit() {
            if (!this.tradeState) {
                return this.$message.error('请选择新交易状态');
            }
            let data = {
                param: {
                    id: this.arrive.id,
                    tradeState: this.tradeState,
                },
            };
            arriveUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.$emit('close');
                    this.tradeState = '';
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.contact_info_box {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    .title {
        line-height: 1.2;
        margin-top: 12px;
        margin-bottom: 12px;
        font-size: 16px;
        color: #333;
        padding-left: 10px;
        > i {
            color: #2370eb;
            font-size: 18px;
        }
        button {
            background: rgb(255, 255, 255);
            color: rgb(35, 112, 235);
            border-color: rgb(35, 112, 235);
            padding: 5px 10px;
            float: right;
            margin-right: 20px;
        }
    }
    .el-form {
        margin-left: 25px;
        /deep/ .el-form-item__label {
            font-weight: 600 !important;
        }
    }
    .info {
        font-size: 14px;
        color: #333;
        padding-left: 107px;
        box-sizing: border-box;
        margin-top: 15px;
        span {
            display: inline-block;
            width: 90px;
            i {
                margin-right: 5px;
            }
        }
    }
}
.footer_btn {
    width: 100%;
    height: 50px;
    line-height: 50px;
    text-align: right;
    border-top: 1px solid #e5e5e5;
    background-color: #fff;
    .el-button--primary {
        background-color: #2370eb;
        border-color: #2370eb;
    }
    button:last-child {
        margin-right: 30px;
    }
}
</style>
