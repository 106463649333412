<template>
    <div class="table-box">
        <el-table
            ref="multipleTable"
            :data="tableData"
            height="100%"
            :header-cell-style="{
                background: '#FAFAFA',
                color: '#333333',
                'font-size': '14px',
                'font-weight': '600',
            }"
        >
            <el-table-column label="交易时间" show-overflow-tooltip width="93">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.tradeTime
                            ? scope.row.tradeTime.slice(0, 10)
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="交易单号" show-overflow-tooltip width="240">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.tradeNo ? scope.row.tradeNo : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="交易状态" show-overflow-tooltip width="105">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.tradeState
                            ? scope.row.tradeState == 1
                                ? '已完成'
                                : scope.row.tradeState == 3
                                ? '已完成有退款'
                                : '- -'
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="付款人" show-overflow-tooltip width="60">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.externalUserName
                            ? scope.row.externalUserName
                            : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column
                label="收款总金额"
                show-overflow-tooltip
                width="90"
            >
                <template slot-scope="scope">
                    <span>{{
                        scope.row.amount ? scope.row.amount : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="收款人" show-overflow-tooltip width="60">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.adminName ? scope.row.adminName : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="核销状态" show-overflow-tooltip width="80">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.contractPlanId ? '已核销' : '未核销'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="收款备注" show-overflow-tooltip width="173">
                <template slot-scope="scope">
                    <span>{{
                        scope.row.remark ? scope.row.remark : '- -'
                    }}</span>
                </template>
            </el-table-column>
            <el-table-column min-width="20"> </el-table-column>
            <el-table-column label="操作" fixed="right" width="130px">
                <template slot-scope="scope">
                    <el-button
                        style="color: #2370eb"
                        type="text"
                        @click="onSet(scope.row)"
                    >
                        设置状态
                    </el-button>
                </template>
            </el-table-column>
            <div slot="empty" class="empty">
                <img src="@/assets/zanwushuju.png" />
                <span style="display: block">暂无数据～</span>
            </div>
        </el-table>
    </div>
</template>
<script>
import { arriveList } from '@/api/arrive/arrive.js';
export default {
    data() {
        return {
            tableData: [],
            btnP: {},
        };
    },
    methods: {
        // 获取列表
        getData(searchVal, currentPage, pagesize, searchDate, btnP) {
            this.btnP = btnP;
            let data = {
                param: {},
                pageNum: currentPage,
                pageSize: pagesize,
            };
            if (btnP.userSearch && !btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');

                if (searchVal.adminId) {
                    data.param.adminId = searchVal.adminId;
                }
            }

            if (btnP.depSearch) {
                if (searchVal.adminId) {
                    data.param.adminId = searchVal.adminId;
                }
                if (searchVal.departmentId) {
                    data.param.departmentId = searchVal.departmentId;
                }
            }
            if (searchVal.tradeState == '已完成') {
                data.param.tradeState = 1;
            }
            if (searchVal.tradeState == '已完成有退款') {
                data.param.tradeState = 3;
            }
            if (!btnP.userSearch && !btnP.depSearch) {
                data.param.departmentId =
                    sessionStorage.getItem('departmentId');
                data.param.adminId = sessionStorage.getItem('adminId');
            }
            if (searchDate && searchDate.length > 0) {
                data.param.startArriveDate = searchDate[0];
                data.param.endArriveDate = searchDate[1];
            }
            if (searchVal.name) {
                data.param.name = searchVal.name;
            }
            arriveList(data).then((res) => {
                if (res.code == 200) {
                    this.tableData = res.data.list;
                    this.$emit('totalNum', res.data.total);
                }
            });
        },
        // 设置状态
        onSet(data) {
            this.$emit('onSet', data);
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.table-box {
    width: 100%;
    height: 100%;

    .tableBtn-box {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 40px;
    }
    .tableBtn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        margin-left: 10px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        height: 40px;
        button {
            width: 20px;
            height: 20px;
            margin: 0;
            border-radius: 0;
            background: transparent;
            border: none;
            position: relative;
        }
        button:nth-child(1) {
            line-height: 2;
        }
        button:nth-child(2) {
            /deep/ .el-icon-caret-bottom {
                position: absolute;
                top: -3px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
    /deep/ .disableheadselection > .cell .el-checkbox__inner {
        display: none;
    }
    .el-table {
        /deep/tr td:nth-child(1) .cell,
        /deep/tr td:nth-child(6) .cell {
            padding-right: 20px;
            position: relative;
            .mini_circle {
                content: '';
                position: absolute;
                top: 2px;
                right: 0;
                width: 20px;
                height: 16px;
                background: #ff9385;
                border-radius: 63px 63px 63px 1px;
                font-size: 12px;
                font-style: normal;
                text-align: center;
                line-height: 16px;
                color: #fff;
            }
        }
    }
}

.tooltip_con_bot {
    //   padding-top: 30px;
}
.left_toast {
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 12px;
    line-height: 1.2;
}
.sale_order {
    display: flex;
    align-items: center;
    p {
        color: #2370eb;
    }
    div {
        color: #333;
    }
    i {
        cursor: pointer;
        margin-left: 10px;
        color: #2370eb;
    }
}
</style>
<style lang="scss"></style>
