<template>
    <div class="declare_box">
        <div class="search-box">
            <div class="search-one">
                <div class="left">
                    <span>记录筛选</span>
                    <el-input
                        size="small"
                        style="width: 220px"
                        placeholder="请输入交易单号"
                        v-model.trim="searchVal.name"
                        clearable
                    >
                    </el-input>
                    <el-button
                        @click="getTableData()"
                        type="primary"
                        style="margin-right: 0"
                        size="small"
                        icon="el-icon-search"
                    ></el-button>
                    <span>交易时间</span>
                    <el-date-picker
                        v-model="searchDate"
                        size="small"
                        type="daterange"
                        style="margin-left: 0"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        clearable
                        @change="getTableData"
                    >
                    </el-date-picker>
                </div>
                <p>
                    <el-button
                        class="right"
                        type="primary"
                        style="margin-left: 29px"
                        size="small"
                        @click="getTableData()"
                        >查询</el-button
                    >
                    <el-button
                        class="right"
                        type="primary"
                        style="
                            margin: 0 16px 0 0;
                            background: none;
                            border-color: #d9d9d9;
                            color: #333333;
                        "
                        icon="el-icon-refresh-left"
                        size="small"
                        @click="emptyData"
                        >重置</el-button
                    >
                </p>
            </div>
            <div class="search-two">
                <!-- <span v-if="btnP.depSearch">部门</span>
                <el-select
                    v-if="btnP.depSearch"
                    v-model="searchVal.departmentId"
                    size="small"
                    filterable
                    style="width: 120px"
                    clearable
                    placeholder="请选择"
                    @change="changeDepartmentId"
                >
                    <el-option
                        v-for="item in departmentList"
                        :key="item.id"
                        :label="item.departmentName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                <span v-if="btnP.userSearch">成员</span>
                <el-select
                    v-if="btnP.userSearch"
                    style="width: 120px"
                    v-model="searchVal.adminId"
                    size="small"
                    filterable
                    clearable
                    placeholder="请选择"
                    @change="getTableData"
                >
                    <el-option
                        v-for="item in userName"
                        :key="item.id"
                        :label="item.nickName"
                        :value="item.id"
                    >
                    </el-option>
                </el-select> -->
                <Department
                    ref="department"
                    :departmentWidth="120"
                    :adminWidth="120"
                    :depSearch="btnP.depSearch"
                    :searchUser="btnP.userSearch"
                    @searchData="searchData"
                ></Department>
                <span>交易状态</span>
                <el-radio-group v-model="searchVal.tradeState" size="small">
                    <el-radio-button
                        label="已完成"
                        @click.native.prevent="clickitem('已完成')"
                    ></el-radio-button>
                    <el-radio-button
                        label="已完成有退款"
                        @click.native.prevent="clickitem('已完成有退款')"
                    ></el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div class="table-title">
            <p>收款列表</p>
        </div>
        <div class="table">
            <Table ref="table" @onSet="onSet" @totalNum="totalNum"></Table>
        </div>
        <div class="page-box">
            <el-pagination
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
                :current-page.sync="currentPage"
                :page-size="pagesize"
                :page-sizes="[10, 20, 50]"
                layout="total,sizes,prev, pager, next, jumper"
                :total="total"
            ></el-pagination>
        </div>
        <!-- 设置状态 -->
        <el-dialog
            :visible.sync="dialogSetStatus"
            width="560px"
            :before-close="handleClose"
            append-to-body
        >
            <template slot="title">
                <div
                    style="
                        display: flex;
                        align-items: center;
                        color: #000000;
                        font-size: 16px;
                        font-weight: 500;
                    "
                >
                    设置状态
                </div>
            </template>
            <Set ref="set" @close="handleClose"></Set>
        </el-dialog>
    </div>
</template>

<script>
import { roleList } from '@/api/customer/customer';
import { departmentList } from '@/api/admin/framework/framework.js';
import { arriveGet } from '@/api/arrive/arrive.js';
import Table from '../table/table.vue';
import Set from '../set/set.vue';
import Department from '../../../../components/Department';
export default {
    name: '',
    props: {},
    data() {
        return {
            searchVal: {
                adminId: '',
            },
            btnP: {},
            searchDate: [],
            currentPage: 1,
            pagesize: 20,
            total: 0,
            dialogSetStatus: false,
            tableRow: {},
            userName: [],
            departmentList: [],
        };
    },
    components: {
        Table,
        Set,
        Department,
    },
    created() {},
    mounted() {
        this.getBtn();
    },
    methods: {
        // 获取按钮权限
        getBtn() {
            var btnArr = JSON.parse(sessionStorage.getItem('isButArr'));
            // console.log(btnArr);
            let btn = {};
            for (let i in btnArr) {
                btn['' + btnArr[i].route] = true;
                if (btnArr[i].children && btnArr[i].children.length > 0) {
                    btnArr[i].children.forEach((item) => {
                        btn['' + item.route] = true;
                        if (item.children && item.children.length > 0) {
                            item.children.forEach((itm) => {
                                btn['' + itm.route] = true;
                            });
                        }
                    });
                }
            }
            this.btnP = btn;
            if (btn.depSearch) {
                this.getDepartmentList();
            }
            if (btn.userSearch && !btn.depSearch) {
                this.$refs.department.getUserList(
                    sessionStorage.getItem('departmentId')
                );
            }
            this.getData();
        },
        // 获取部门
        getDepartmentList() {
            let data = {
                param: {},
            };
            departmentList(data).then((res) => {
                // console.log(res);
                if (res.code == 200) {
                    this.departmentList = res.data;
                    this.$refs.department.getData(this.btnP, res.data);
                }
            });
        },
        searchData(data) {
            this.pagesize = 10;
            this.pageNum = 1;
            this.searchVal.departmentId = data.departmentId;
            this.searchVal.adminId = data.adminId;
            this.getData();
        },
        getData() {
            setTimeout(() => {
                this.$refs.table.getData(
                    this.searchVal,
                    this.currentPage,
                    this.pagesize,
                    this.searchDate,
                    this.btnP
                );
            }, 0);
        },
        totalNum(data) {
            this.total = data;
        },
        getTableData() {
            this.currentPage = 1;
            this.pagesize = 20;
            this.getData();
        },
        // 分页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getData();
        },
        handleSizeChange(val) {
            this.pagesize = val;
            this.getData();
        },
        // 交易状态
        clickitem(e) {
            e === this.searchVal.tradeState
                ? (this.searchVal.tradeState = '')
                : (this.searchVal.tradeState = e);
            this.getTableData();
        },
        arriveGet(id) {
            let data = {
                param: {
                    id,
                },
            };
            arriveGet(data).then((res) => {
                if (res.code == 200) {
                    setTimeout(() => {
                        this.$refs.set.getData(res.data);
                    }, 0);
                }
            });
        },
        onSet(data) {
            this.dialogSetStatus = true;
            this.arriveGet(data.id);
        },
        handleClose() {
            this.dialogSetStatus = false;
            this.getTableData();
            this.tableRow = {};
        },
        // 重置
        emptyData() {
            this.tableRow = {};
            this.searchVal = {
                adminId: '',
            };
            this.searchDate = [];
            this.getTableData();
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.declare_box {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    .search-box {
        width: 100%;
        background: #fff;
        border-radius: 4px;
        margin-bottom: 16px;
        span {
            font-size: 12px;
            font-weight: 600;
            color: #000;
            margin: 0 16px;
        }
        .search-one {
            margin: 10px 0 12px 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .left {
                button {
                    background: #2370eb;
                    border-radius: 2px;
                    border-color: #2370eb;
                    margin-right: 16px;
                }
                .el-range-editor--small.el-input__inner {
                    vertical-align: middle;
                    margin-left: 19px;
                }
            }
            .right {
                background: #2370eb;
                border-radius: 2px;
                border-color: #2370eb;
                margin-right: 16px;
            }
        }
    }
    .table-title {
        background: #fff;
        padding: 16px;
        p {
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            line-height: 20px;
        }
    }
    .table {
        flex: 1;
    }
    .search-two {
        margin-bottom: 10px;
    }
    .page-box {
        background: #fff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .el-icon-sort-down {
        transform: rotateX(180deg) rotateZ(270deg);
        margin-left: 5px;
        color: #c0c4cc;
        vertical-align: middle;
    }
    .money_input {
        display: inline-block;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        /deep/ .el-input__inner {
            border: none;
            padding: 0 15px;
        }
    }
}
.dialog_info {
    width: 80%;
    text-align: center;
    margin: 25px auto 150px;
    font-size: 14px;
    color: #333333;
    p {
        span {
            // color: #666;
        }
    }
    p + p {
        margin-top: 16px;
    }
    .icon-zhuyi {
        display: block;
        margin: 0 auto 45px;
        font-size: 40px;
        color: #feb516;
    }
}
/deep/ .el-dialog__footer {
    padding: 0;
}
.bottom-but {
    width: 100%;
    height: 48px;
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #e5e5e5;
}
// 处理input type = number的上下箭头
/deep/ input::-webkit-outer-spin-button,
/deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/deep/ input[type='number'] {
    -moz-appearance: textfield;
}
</style>
